// import {Modernizr} from './vendor';
import { MOBILMEDIA } from './helper/constants';
import { $, $$, addEventListenerAll } from './helper/utils';
import outline from './helper/outline';

outline();

let mobileNavLoaded = false;

const loadMobileNav = () => {
    import('./components/navigation/mobile.js')
        .then((mobileNav) => {
            mobileNavLoaded = true;
            return mobileNav.default();
        }).catch((err) => {
            console.warn(`Chunk navigation/mobile.js loading failed: ${err}`);
        });
};
export default function () {
    const isMobile = document.documentElement.classList.contains('is-mobile');
    const revealElements = $$('.reveal');
    const revealOptions = {
        from: { scale: 0.97, y: 25, opacity: 0 },
        to: {
            scale: 1,
            y: 0,
            opacity: 1,
            ease: Sine.easeIn,
        },
        duration: 0.45,
        staggerDelay: 0.25,
        observer: {
            root: null,
            rootMargin: '0px',
            threshold: 0.05,
        },
    };
    let customSelectLoaded = false;

    if (revealElements.length > 0 && isMobile === false) {
        import('./components/ElementReveal').then((E) => {
            return new E.ElementReveal(revealElements, revealOptions);
        }).catch((err) => {
            console.warn(`Chunk ElementReveal.js loading failed: ${err}`);
        });
    }

    import('./components/header.js').then((header) => {
        return header.default();
    }).catch((err) => {
        console.warn(`Chunk header.js loading failed: ${err}`);
    });

    const loadCustomSelect = () => {
        if ($('.custom-select') !== null) {
            import('./components/select.js').then((select) => {
                customSelectLoaded = true;
                return select.init();
            }).catch((err) => {
                console.warn('Chunk select.js loading failed', err);
            });
        }
    };

    // conditionaly load scripts that are only needed for certain viewports
    // but only if the viewport changed
    MOBILMEDIA.addListener((e) => {
        if (e.matches === false) {
            if (customSelectLoaded === false) loadCustomSelect();
        } else {
            // eslint-disable-next-line
            if (mobileNavLoaded === false) loadMobileNav();
        }
    });

    // conditionaly load scripts that are only needed for certain viewports
    if (MOBILMEDIA.matches === false) {
        loadCustomSelect();
    } else {
        loadMobileNav();
    }

    if ($('.selectr-select') !== null) {
        import('./components/selectWithSearch.js').then((select) => {
            customSelectLoaded = true;
            return select.init();
        }).catch((err) => {
            console.warn('Chunk selectWithSearch.js loading failed', err);
        });
    }

    if ($('.scrollto') !== null) {
        import('./components/smoothScroll.js').then((scroll) => {
            return scroll.init();
        }).catch((err) => {
            console.warn(`Chunk smoothScroll.js loading failed: ${err}`);
        });
    }

    if ($('.filter-list') !== null) {
        import('./components/gridFilter.js').then((filter) => {
            return filter.init();
        }).catch((err) => {
            console.warn(`Chunk gridFilter.js loading failed: ${err}`);
        });
    }

    if ($('.agegate') !== null) {
        import('./components/ageGate.js').then((agegate) => {
            return agegate.init();
        }).catch((err) => {
            console.warn(`Chunk agegate.js loading failed: ${err}`);
        });
    }

    if ($('.home') !== null) {
        import('./pages/home.js').then((home) => {
            return home.init();
        }).catch((err) => {
            console.warn(`Chunk home.js loading failed: ${err}`);
        });
    }

    if ($('.open-in-shop') !== null) {
        import('./pages/open-shop.js').then((home) => {
            return home.init();
        }).catch((err) => {
            console.warn(`Chunk home.js loading failed: ${err}`);
        });
    }

    if ($('.history') !== null) {
        const SMALLSCREEN = window.matchMedia('(min-width: 992px) and (max-height: 700px)');
        const reload = () => {
            window.scrollTo(0, 0);
            window.location.reload();
        };

        // prevent site style breaking
        MOBILMEDIA.addListener(reload);
        SMALLSCREEN.addListener(reload);

        if (/Mobi/i.test(window.navigator.userAgent) === false && window.innerHeight > 700) {
            import('./pages/history.js').then((history) => {
                return history.init();
            }).catch((err) => {
                console.warn(`Chunk history.js loading failed: ${err}`);
            });
        }
    }

    if ($('.custom-modal') !== null) {
        import('./components/customModal')
            .catch((err) => {
                console.warn(`Chunk ageGate.js loading failed: ${err}`);
            });
    }

    if ($('.register') !== null) {
        import('./components/register.js').then((register) => {
            return register.init();
        }).catch((err) => {
            console.warn(`Chunk register.js loading failed: ${err}`);
        });
    }

    if ($('.date-picker') !== null) {
        import('./components/datePicker.js').then((date) => {
            return date.default();
        }).catch((err) => {
            console.warn(`Chunk datePicker.js loading failed: ${err}`);
        });
    }

    if ($('.sso-form') !== null) {
        import('./components/sso.js').then((sso) => {
            return sso.init();
        }).catch((err) => {
            console.warn(`Chunk sso.js loading failed: ${err}`);
        });
    }

    if ($('.form') !== null) {
        import('./components/form.js').then((form) => {
            return form.default();
        }).catch((err) => {
            console.warn(`Chunk form.js loading failed: ${err}`);
        });
    }

    if ($('.sticky-text-wrapper') !== null && document.documentElement.classList.contains('ie') === false) {
        import('./components/textReveal.js').then((text) => {
            return text.init();
        }).catch((err) => {
            console.warn(`Chunk textReveal.js loading failed: ${err}`);
        });
    }
    if ($('.calendar-add') !== null) {
        import('./components/addEvent.js').then((calendar) => {
            return calendar.init();
        }).catch((err) => {
            console.warn(`Chunk addEvent.js loading failed: ${err}`);
        });
    }

    if ($('.split') !== null && isMobile === false) {
        import('./components/typewriter.js').then((typewriter) => {
            return typewriter.init();
        }).catch((err) => {
            console.warn(`Chunk typewriter.js loading failed: ${err}`);
        });
    }

    if ($('.range') !== null) {
        import('./components/range.js').then((range) => {
            return range.default();
        }).catch((err) => {
            console.warn(`Chunk range.js loading failed: ${err}`);
        });
    }

    if ($('.blog-detail .infinite') !== null) {
        import('./pages/blog-detail.js').then((blog) => {
            return blog.init(revealOptions);
        }).catch((err) => {
            console.warn(`Chunk blog-detail.js loading failed: ${err}`);
        });
    }

    if ($('.overview') !== null || $('.masterblender-filter') !== null || $('.community-stories') !== null) {
        import('./components/filters.js').then((filters) => {
            console.log('filters', filters);
            return filters.init();
        }).catch((err) => {
            console.warn(`Chunk filters.js loading failed: ${err}`);
        });
    }

    if ($('.academy') !== null) {
        import('./pages/academy.js').then((academy) => {
            return academy.init();
        }).catch((err) => {
            console.warn(`Chunk academy.js loading failed: ${err}`);
        });
    }

    if ($('.partner') !== null) {
        import('./pages/partner.js').then((partner) => {
            return partner.init();
        }).catch((err) => {
            console.warn(`Chunk partner.js loading failed: ${err}`);
        });
    }

    if ($('#pr-search input') !== null) {
        import('./pages/pressrelease.js').then((pressrelease) => {
            return pressrelease.init();
        }).catch((err) => {
            console.warn(`Chunk pressrelease.js loading failed: ${err}`);
        });
    }

    if ($('.image-slider.swiper-container') !== null) {
        import('./components/slider.js').then((slider) => {
            return slider.imageSlider();
        }).catch((err) => {
            console.warn(`Chunk slider.js loading failed: ${err}`);
        });
    }

    if ($('.lounge-locator') !== null) {
        import('./components/locator/').then((loungeLocator) => {
            return loungeLocator.default();
        }).catch((err) => {
            console.warn(`Chunk loungeLocator.js loading failed: ${err}`);
        });
    }

    if ($('.language-switch') !== null) {
        import('./components/language.js').then((loungeLocator) => {
            return loungeLocator.init();
        }).catch((err) => {
            console.warn(`Chunk language.js loading failed: ${err}`);
        });
    }

    if ($('.cigar-butler-questions') !== null) {
        import('./components/butler/cigarButlerQuestions.js').then((cigarButlerQuestions) => {
            return cigarButlerQuestions.default();
        }).catch((err) => {
            console.warn(`Chunk cigarButlerQuestions.js loading failed: ${err}`);
        });
    }

    if ($('.cigar-butler-app') !== null) {
        import('./components/butler/cigarButler.js').then((cigarButler) => {
            return cigarButler.default();
        }).catch((err) => {
            console.warn(`Chunk cigarButler.js loading failed: ${err}`);
        });
    }

    if ($('.cookie-policy') !== null) {
        import('./components/cookie.js').then((cookie) => {
            return cookie.init();
        }).catch((err) => {
            console.warn(`Chunk cookie.js loading failed: ${err}`);
        });
    }

    if ($('.back-to-top') !== null) {
        import('./components/backToTop.js').then((backToTop) => {
            return backToTop.init();
        }).catch((err) => {
            console.warn(`Chunk backToTop.js loading failed: ${err}`);
        });
    }
    if ($('[data-pdfviewer]') !== null) {
        import('./components/pdfviewer.js').then((pdfviewer) => {
            return pdfviewer.init();
        }).catch((err) => {
            console.warn(`Chunk pdfviewer.js loading failed: ${err}`);
        });
    }
    if ($('[data-pdfpage]') !== null) {
        import('./components/pdfpage.js').then((pdfpage) => {
            return pdfpage.init();
        }).catch((err) => {
            console.warn(`Chunk pdfpage.js loading failed: ${err}`);
        });
    }
    if ($('.hubspot-form-load') !== null) {
        import('./components/loadHubSpotForm.js').then((loadHubSpotForm) => {
            import('./components/hubsportForm.js').then((hubsportForm) => {
                loadHubSpotForm.init();
                return hubsportForm.default();
            }).catch((err) => {
                console.warn(`Chunk hubsportForm.js loading failed: ${err}`);
            });
        }).catch((err) => {
            console.warn(`Chunk loadHubSpotForm.js loading failed: ${err}`);
        });
    }

    if ($('.zoom-card') !== null) {
        import('./components/zoomCardModal.js').then((zoomCardModal) => {
            return zoomCardModal.default();
        }).catch((err) => {
            console.warn(`Chunk zoomCardModal.js loading failed: ${err}`);
        });
    }

    if ($('.hempform-contentbox-content') !== null) {
        import('./pages/hempCalendar.js').then((hempCalendar) => {
            return hempCalendar.default();
        }).catch((err) => {
            console.warn(`Chunk hempCalendar.js loading failed: ${err}`);
        });
    }

    if ($('.career') !== null) {
        import('./components/career.js').then((career) => {
            return career.default();
        }).catch((err) => {
            console.warn(`Chunk career.js loading failed: ${err}`);
        });
    }
    if ($('[data-hubspot-rendered]') !== null) {
        import('./components/hubsportForm.js').then((hubsportForm) => {
            return hubsportForm.default();
        }).catch((err) => {
            console.warn(`Chunk hubsportForm.js loading failed: ${err}`);
        });
    }

    //
    // if ($('.profile-update-modal') !== null) {
    //     import('./components/profileUpdate.js').then(profileUpdate => {
    //         return profileUpdate.init();
    //     }).catch(err => {
    //         console.warn(`Chunk profileUpdate.js loading failed: ${err}`);
    //     })
    // }

    if ($('.advents-campaign') !== null) {
        import('./pages/advent.js')
            .catch((err) => {
                console.warn(`Chunk advent.js loading failed: ${err}`);
            });
    }

    const modals = $$('.text-modal');
    if (modals.length > 0) {
        import('./components/modal.js').then((modal) => {
            const Modal = modal.default;
            addEventListenerAll(modals, 'click', function (e) {
                e.preventDefault();

                if ('modal' in this === false) {
                    const tm = Modal(this);
                    this.modal.modal = tm;
                }

                this.modal.modal.show(e);
            });

            // close modals on escape key press
            window.addEventListener('keyup', (e) => {
                if (e.keyCode == 27) {
                    for (let i = 0; i < modals.length; i++) {
                        const m = modals[i];
                        if ('modal' in m && m.modal.open === true) {
                            m.modal.modal.close();
                        }
                    }
                }
            });
        }).catch((err) => {
            console.warn(`Chunk modal.js loading failed: ${err}`);
        });
    }

    if ($('.nav-tabs') !== null || $('[data-toggle="collapse"]') !== null) {
        import('../libs/bs-4-native').then((bsn) => {
            if ('bootstrapNative' in window === false) {
                window.bootstrapNative = bsn;
            }
            const tabs = $$('.nav-tabs .tab-link');
            for (let i = 0; i < tabs.length; i++) {
                const el = tabs[i];
                // fade in new tab content
                el.addEventListener('hide.bs.tab', (e) => {
                    const href = e.relatedTarget.getAttribute('href');
                    TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
                }, false);
            }
        }).catch((err) => {
            console.warn('Chunk bs4-native.js loading failed', err);
        });
    }
}
