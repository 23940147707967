'use-strict';

/**
 * Get a single element for the specified selector.
 * Alias for document.querySelector.
 *
 * @param {string} selector
 * @returns HTMLElement
 */
export const $ = function (selector) {
    return document.querySelector(selector);
};

/**
 * Get all elements for the specified selector.
 * Alias for document.querySelectorAll.
 *
 * @param {string} selector
 * @returns NodeList
 */
export const $$ = function (selector) {
    return document.querySelectorAll(selector);
};

/**
 * Get the element for the specified id selector.
 * Alias for document.getElementById.
 *
 * @param {string} id
 * @returns HTMLElement
 */
export const $id = function (id) {
    return document.getElementById(id);
};

/**
 * Get an element's window offset bounds
 *
 * @param {HTMLElement} el
 * @returns {object} Object containing postions and dimensions
 */
export const offset = (el) => {
    const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft, bottom: rect.top + scrollTop + rect.height, width: rect.width, height: rect.height };
};

/**
 * Delegate an event. Uses event bubbling to trigger only for the specified elements.
 * Usefuel if there are a lot of elements that need the same event without adding
 * a lot of individual event listeners.
 *
 * @param {HTMLElement} element The element to attach the event listener to
 * @param {string} eventName The event type to listen to
 * @param {string} selector A selector to get the elements that should listen to the event
 * @param {function} callback The function to execute
 * @param {object} options Optional options for the event listener
 * @returns {void}
 */
export const delegate = function (element, eventName, selector, callback, options = {}) {
    if (element === null) {
        console.warn('Element not found', { el: element });
        return;
    }

    element.addEventListener(eventName, function (event) {
        const possibleTargets = element.querySelectorAll(selector);
        const target = event.target;

        for (let i = 0, l = possibleTargets.length; i < l; i++) {
            let el = target;
            const p = possibleTargets[i];

            while (el && el !== element) {
                if (el === p) {
                    return callback.call(p, event);
                }

                el = el.parentNode;
            }
        }
    }, options);
};

/**
 * Add an event listener to and Array/NodeList of DOM Elements
 *
 * @param {array} elements The elements to add the eventlistener to
 * @param {string} eventName The event type to listen to
 * @param {function} callback The function to execute
 * @param {object} options Optional options for the event listener
 * @returns {void}
 */
export const addEventListenerAll = (elements, eventName, callback, options = {}) => {
    for (let i = 0; i < elements.length; i++) {
        const el = elements[i];
        el.addEventListener(eventName, callback, options);
    }
};

/**
 * Load content from an url
 *
 * @param {string} link The url to load data from.
 * @param {string} type The data return type. If not json will return text.
 * @returns Promise
 */
export const loadFromUrl = (link, type) => {
    return new Promise((resolve, reject) => {
        fetch(link, {
            credentials: 'same-origin'
        }).then(response => {
            if (type === 'json') {
                return response.json();
            } else {
                return response.text();
            }
        }).then(res => {
            resolve(res);
        }).catch(reason => {
            reject(reason);
        });
    });
};

export const updateUrl = (newUrl, push = false) => {
    // update url
    const updateType = push === true ? 'pushState' : 'replaceState';
    window.history[updateType](newUrl, '', newUrl);
};

export const throttle = function (func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        var now = Date.now();
        if (!previous && options.leading === false) previous = now;
        var remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
};

/**
 * Get the closest parent element for a css class
 *
 * @param {HTMLElement} el
 * @param {string} cssclass
 * @returns {HTMLElement}
 */
export const closestParent = (el, cssclass) => {
    let parent = el.parentElement;
    while (parent) {
        // don't go higher than the body element
        if (parent.nodeName === 'BODY') {
            break;
        }

        // element found
        if (parent.classList.contains(cssclass) === true) {
            break;
        }

        parent = parent.parentElement;
    }

    return parent;
};

export const nodeListToArray = (nodeList) => {
    const arr = [];
    for (let i = nodeList.length; i--; arr.unshift(nodeList[i]));
    return arr;
};

export const toggleElementVisibility = (elments, action) => {
    const len = elments.length;
    if (len === 0) {
        return;
    }

    switch (action) {
        case 'hide':
            for (let i = 0; i < len; i++) {
                elments[i].setAttribute('hidden', '');
            }
            break;
        default:
            for (let i = 0; i < len; i++) {
                if (elments[i].hasAttribute('hidden')) {
                    elments[i].removeAttribute('hidden', '');
                }
            }
            break;
    }
};

/**
 * Create a query string from an object
 *
 * @param {object} params
 * @returns {string}
 */
export const queryString = (params, q = true) => {
    let query = q ? '?' : '';
    return query + Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
};

/**
 * Find the index from a DOM Element
 *
 * @param {object} params
 * @returns {Int}
 */
export const getElementIndex = (node) => {
    const prev = node.previousElementSibling;
    let index = 0;

    if (prev !== null) {
        const parent = node.parentElement;

        if (parent !== null) {
            const { children } = parent;
            for (let i = 0; i < children.length; i++) {
                const el = children[i];
                if (el === node) {
                    index = i;
                    break;
                }
            }
        }
    }

    return index;
};

/**
 * Get all selected options of a multi select
 *
 * @param {HTMLElement} select The select element
 * @returns {array}
 */
export const selectedOptions = (select) => {
    const selected = [];
    const options = select.querySelectorAll('option');
    for (let i = 0; i < options.length; i++) {
        const opt = options[i];
        if (opt.hasAttribute('selected')) {
            selected.push(opt.value);
        }
    }

    return selected;
};

export const fixedEncodeURIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
};

// match text case insensitive
const pregQuote = (str) => {
    return (str + '').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, '\\$1');
};

const accentMap = {
    'ä': 'a',
    'ö': 'o',
    'ü': 'u',
    'á': 'a',
    'é': 'e',
    'è': 'e',
    'í': 'i',
    'ó': 'o',
    'ú': 'u',
    'Ç': 'c'
};

const accentFold = (s) => {
    if (!s) { return ''; }
    var ret = '';
    for (var i = 0; i < s.length; i++) {
        ret += accentMap[s.charAt(i)] || s.charAt(i);
    }
    return ret;
};

// https://stackoverflow.com/a/280805
export const highlight = (data, query) => {
    const quoted = pregQuote(query);
    const exactMatch = data.replace(new RegExp(`(${quoted})`, 'gi'), '<mark>$1</mark>');

    // return if we have an exact match
    if (exactMatch.indexOf('<mark>') !== -1) {
        return exactMatch;
    }

    // accent fold query and text string
    const foldedText = accentFold(data);
    const foldedQuery = accentFold(quoted);
    // mark query in folded text
    const foldedMatch = foldedText.replace(new RegExp(`(${foldedQuery})`, 'gi'), '{$1}');
    // get position of markers
    const firstIndex = foldedMatch.indexOf('{');
    const lastIndex = foldedMatch.indexOf('}');
    // get the actual string to mark from the marker indexes
    const textToMark = data.substring(firstIndex, lastIndex - 1);
    return data.replace(new RegExp(`(${textToMark})`, 'gi'), '<mark>$1</mark>');
};

export const generateUUID = () => {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0; // eslint-disable-line no-bitwise
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16); // eslint-disable-line
    });
};

export const randomStringID = () => (
    Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)
);

/**
 * Collapse without animation
 *
 * @param {Event} e
 * @param {HTMLElement} el The element that would control the collapse
 * @returns {void}
 */
export const expandCollapse = (e, el) => {
    e.preventDefault();
    const control = el || this;

    if (control.classList.contains('collapsed')) {
        control.classList.remove('collapsed');
        control.setAttribute('aria-expanded', true);
        const target = document.querySelector(control.getAttribute('href'));
        target.setAttribute('aria-expanded', true);
        target.classList.add('show');
    } else {
        control.classList.add('collapsed');
        control.setAttribute('aria-expanded', false);
        const target = document.querySelector(control.getAttribute('href'));
        target.setAttribute('aria-expanded', false);
        target.classList.remove('show');
    }
};

/**
 * Throttle a function call with requestAnimationFram
 *
 * @param {function} callback The function to throttle
 * @returns {function} The throttled function
 */
export const rafThrottle = (callback, context = null) => {
    const self = context || this;
    let requestId;

    const later = (context, args) => () => {
        requestId = null;
        callback.apply(context, args);
    };

    const throttled = (...args) => {
        if ((requestId === null) || (requestId === undefined)) {
            requestId = requestAnimationFrame(later(self, args));
        }
    };

    throttled.cancel = () => {
        cancelAnimationFrame(requestId);
    };

    return throttled;
};

/**
 * Loads an image with progress callback.
 *
 * The `onprogress` callback will be called by XMLHttpRequest's onprogress
 * event, and will receive the loading progress ratio as an whole number.
 * However, if it's not possible to compute the progress ratio, `onprogress`
 * will be called only once passing -1 as progress value. This is useful to,
 * for example, change the progress animation to an undefined animation.
 * https://stackoverflow.com/a/42196770
 *
 * @param {string} imageUrl The image to load
 * @param {Function} onprogress
 * @return {Promise}
 */
export const loadImage = (imageUrl, onprogress) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        let notifiedNotComputable = false;
        let callProgressCallback = false;
        const timeout = setTimeout(() => {
            callProgressCallback = true;
        }, 200);

        xhr.open('GET', imageUrl, true);
        xhr.responseType = 'arraybuffer';

        xhr.onprogress = function (ev) {
            if (ev.lengthComputable) {
                const progress = parseInt((ev.loaded / ev.total) * 100);

                if (callProgressCallback === false) {
                    if (progress === 100) {
                        clearTimeout(timeout);
                    }

                    return;
                }

                onprogress(progress);
            } else {
                if (!notifiedNotComputable) {
                    notifiedNotComputable = true;
                    callProgressCallback = true;
                    onprogress(-1);
                }
            }
        };

        xhr.onloadend = function () {
            if (!xhr.status.toString().match(/^2/)) {
                reject(xhr);
            } else {
                if (!notifiedNotComputable && callProgressCallback === true) {
                    onprogress(100);
                }

                const options = {};
                const headers = xhr.getAllResponseHeaders();
                const m = headers.match(/^Content-Type:\s*(.*?)$/mi);

                if (m && m[1]) {
                    options.type = m[1];
                }

                const blob = new Blob([this.response], options);

                resolve(window.URL.createObjectURL(blob));
            }
        };

        xhr.send();
    });
};
