// outline.js
// based on http://www.paciellogroup.com/blog/2012/04/how-to-remove-css-outlines-in-an-accessible-manner/
export default function () {
    var style_element = document.createElement('STYLE'),
        dom_events = 'addEventListener' in document,
        add_event_listener = function (type, callback) {
            // Basic cross-browser event handling
            if (dom_events) {
                document.addEventListener(type, callback);
            } else {
                document.attachEvent('on' + type, callback);
            }
        },
        set_css = function (css_text) {
            // Handle setting of <style> element contents in IE8
            !!style_element.styleSheet ? style_element.styleSheet.cssText = css_text : style_element.innerHTML = css_text;
        };

    document.head.appendChild(style_element);

    // Using mousedown instead of mouseover, so that previously focused elements don't lose focus ring on mouse move
    add_event_listener('mousedown', function () {
        set_css(':focus,.form-control,input.custom + label:before{outline:0!important}::-moz-focus-inner{border:0!important;}');
    });

    add_event_listener('keydown', function () {
        set_css('');
    });
}